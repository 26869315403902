<script>
import { loadStripe } from '@stripe/stripe-js'
import ApiService from '@/services/api.service'
import { StorageService } from '../../services/storage.service'
import StorageConstants from '../../constants/storage.constants'

export default {
  name: 'Subscription',
  data () {
    return {
      stripe: null
    }
  },
  async mounted () {
    this.stripe = await loadStripe(process.env.STRIPE_TOKEN)
    await this.subscribe()
  },
  methods: {
    async subscribe () {
      try {
        const response = await ApiService.post('/subscription/session', {
            userUuid: StorageService.get(StorageConstants.UserUuid)
          }
        )

        const { error } = await this.stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        if (error) {
          console.error('Stripe checkout error', error)
        }
      } catch (error) {
        console.error('Error creating subscription session', error)
      }
    }
  }
}
</script>

<style scoped>
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6772e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #5469d4;
}
</style>
