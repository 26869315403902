const state = () => ({
  envelopeUuid: ''
})

const getters = {
  getEnvelopeUuid (state) {
    return state.envelopeUuid
  }
}

const actions = {
  updateEnvelopeUuid: async function ({ commit, dispatch }, { envelopeUuid }) {
    commit('setEnvelopeUuid', envelopeUuid)
  }
}

const mutations = {
  setEnvelopeUuid (state, envelopeUuid) {
    state.envelopeUuid = envelopeUuid
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
