<template>
  <div id="app">
    <v-app>
      <router-view/>
    </v-app>
  </div>
</template>

<script>

import ApiService from '@/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapGetters('lang', {
      getLanguageCode: 'getLanguageCode'
    })
  },
  created: function () {
    ApiService.mountAuthenticationInterceptor()
    this.$i18n.locale = this.getLanguageCode
  },
  destroyed () {
    ApiService.unmountAuthenticationInterceptor()
  }
}
</script>

<style lang="scss">
@import "./assets/sass/main.scss";

</style>
