<template>
  <v-app class="tw-relative">
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
                           indeterminate
                           :size="70"
                           :width="7"
                           color="primary"
      ></v-progress-circular>
    </div>
    <LetterForm @example="exampleLetter" @preview="previewLetter" @download="downloadLetter"
                @sendAsEnvelope="sendAsEnvelope"/>
    <SendAsEmail @submit="submitDownload" :show-capture="showCapture"
                 :loading-capture="loadingCapture"/>

    <SidebarImage/>
  </v-app>
</template>

<script>
import LetterForm from '@/components/Letter/Create/LetterForm'
import LetterService from '@/services/letter.service'
import SendAsEmail from '@/components/Common/SendAsEmail/SendAsEmail'
import DocumentService from '@/services/document.service'
import UserService from '@/services/user.service'
import SidebarImage from '@/components/SideBar/SidebarImage.vue'

const VUE_APP_BACKEND_API_URL = process.env.VUE_APP_BACKEND_API_URL

export default {
  components: { SendAsEmail, LetterForm, SidebarImage },
  data: function () {
    return {
      loading: false,
      loadingCapture: false,
      showCapture: false,
      documentUuid: null
    }
  },
  mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })
  },
  methods: {
    async previewLetter (letter) {
      this.loading = true
      const documentUuid = await LetterService.createLetter(letter)
      this.loading = false

      const route = this.$router.resolve({ name: 'DocumentPreview', params: { documentUuid: documentUuid } })
      window.open(route.href)
    },
    async exampleLetter () {
      window.open(`${VUE_APP_BACKEND_API_URL}/pdf/examples/letter.pdf`)
    },
    async downloadLetter (letter) {
      this.loading = true
      this.documentUuid = await LetterService.createLetter(letter)
      this.loading = false
      this.showCapture = true
    },
    async submitDownload ({ email, newsletterConsent }) {
      this.loadingCapture = true
      await DocumentService.sendAsMail(this.documentUuid, email)
      this.loadingCapture = false
      this.showCapture = false

      if (newsletterConsent) {
        await UserService.createConsent(this.documentUuid)
      }
    },
    async sendAsEnvelope (letter) {
      this.loading = true
      const documentUuid = await LetterService.createLetter(letter)
      this.loading = false

      const route = this.$router.resolve({ name: 'EnvelopeCreate', params: { documentUuid: documentUuid } })
      window.open(route.href)
    }
  }
}
</script>
