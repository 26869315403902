<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: 20px; margin-right: 8px;">
    <circle cx="16" cy="16" r="4" stroke="#000" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3,11V6c0-1.1,0.9-2,2-2h5" stroke="#000" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10,28H5c-1.1,0-2-0.9-2-2v-5" stroke="#000" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29,21v5c0,1.1-0.9,2-2,2h-5" stroke="#000" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22,4h5c1.1,0,2,0.9,2,2v5" stroke="#000" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FocusIcon'
}
</script>
