import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../stores'
import AuthConstants from '@/constants/auth.constants'
import InvoiceCreate from '@/views/Invoice/InvoiceCreate'
import EnvelopeCreate from '@/views/Envelope/EnvelopeCreate'
import Checkout from '@/views/Payment/Checkout'
import StripeCheckout from '@/views/Payment/Stripe/Checkout'
import Confirmation from '@/views/Payment/Confirmation'
import LetterCreate from '@/views/Letter/LetterCreate'
import DocumentPreview from '@/views/Document/DocumentPreview'
import Login from '../views/Auth/Login.vue'
import DashboardLayout from '@/layouts/DashboardLayout'
import Register from '../views/Auth/Register.vue'
import Subscription from '@/views/Subscription/Subscription.vue'
import SubscriptionCheckout from '@/views/Subscription/SubscriptionCheckout'
import SubscriptionSuccess from '@/views/Subscription/SubscriptionSuccess'
import DonationSuccess from '@/views/Donation/DonationSuccess'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardLayout,
    props: route => {
      let siteTitle = ''
      route.matched.forEach(matched => {
        if (matched.path === route.path) {
          siteTitle = matched.props.default.siteTitle
        }
      })
      return {
        siteTitle
      }
    },
    children: [
      {
        path: '',
        component: InvoiceCreate,
        props: { siteTitle: 'Rechnung' }
      },
      {
        path: 'invoice',
        component: InvoiceCreate,
        props: { siteTitle: 'Rechnung' }
      },
      {
        path: 'letter',
        component: LetterCreate,
        props: { siteTitle: 'Brief' }
      },
      {
        path: '/envelope/create/:documentUuid',
        name: 'EnvelopeCreate',
        component: EnvelopeCreate,
        props: true
      },
      {
        path: '/payment/checkout/:envelopeUuid',
        props: true,
        name: 'Checkout',
        component: StripeCheckout
      },
      {
        path: '/payment/confirmation',
        name: 'Confirmation',
        component: Confirmation,
        props: { siteTitle: 'Payment' }
      },
      {
        path: '/subscription/checkout',
        name: 'SubscriptionCheckout',
        component: SubscriptionCheckout,
        props: { siteTitle: 'Subscribe' }
      },
      {
        path: '/subscription/success',
        name: 'SubscriptionSuccess',
        component: SubscriptionSuccess,
        props: { siteTitle: 'Subscription' }
      },
      {
        path: '/donation/success',
        name: 'DonationSuccess',
        component: DonationSuccess,
        props: { siteTitle: 'Donation' }
      }
    ]
  },
  {
    path: '/invoice/create',
    name: 'InvoiceCreate',
    component: InvoiceCreate
  },
  {
    path: '/letter/create',
    name: 'LetterCreate',
    component: LetterCreate
  },
  {
    path: '/document/:documentUuid/preview',
    name: 'DocumentPreview',
    component: DocumentPreview,
    props: true
  },
  {
    path: '/envelope/create/:documentUuid',
    name: 'EnvelopeCreate',
    component: EnvelopeCreate,
    props: true
  },
  {
    path: '/payment/checkout/:envelopeUuid',
    props: true,
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/payment/confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    props: { siteTitle: 'Login' }
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
    props: { siteTitle: 'Register' }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    props: { siteTitle: 'Subscription' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Store.getters['auth/getLoginApiStatus'] === AuthConstants.LoginApiStatusSuccess) {
      next()
    } else {
      next('/auth/login')
    }
  } else {
    next()
  }
})

export default router
