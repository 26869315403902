<template>
  <v-app>
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
                           indeterminate
                           :size="70"
                           :width="7"
                           color="primary"
      ></v-progress-circular>
    </div>
    <EnvelopeForm @submit="createEnvelope" @previewDocument="previewDocument" :document-uuid="documentUuid"/>
  </v-app>
</template>
<script>
import EnvelopeForm from '@/components/Envelope/EnvelopeForm'
import EnvelopeService from '@/services/envelope.service'
import router from '@/router'

const { VUE_APP_BACKEND_API_PROXY_URL } = process.env

export default {
  components: { EnvelopeForm },
  props: {
    documentUuid: String
  },
  data: function () {
    return {
      loading: false
    }
  },
  mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })
  },
  methods: {
    async createEnvelope (envelope) {
      this.loading = true
      const envelopeUuid = await EnvelopeService.create(this.documentUuid, envelope)
      this.loading = false
      await router.push({ path: `/payment/checkout/${envelopeUuid}` })
    },
    previewDocument () {
      window.open(`${VUE_APP_BACKEND_API_PROXY_URL}/document/${this.documentUuid}`)
    }
  }
}
</script>

<style>

</style>
