<template>
  <Editor v-model="text" editorStyle="height: 300px">
    <template #toolbar>
      <span class="ql-formats">
        <select class="ql-font"></select>
        <select class="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>

      <!-- Text Formatting -->
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>

      <!-- Text Color and Background -->
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>

      <!-- Alignment -->
      <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
      </span>

      <!-- Lists and Indentation -->
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>

      <!-- Clean Formatting -->
      <span class="ql-formats">
        <button class="ql-clean"></button>
      </span>
    </template>
  </Editor>
</template>

<script>
import Editor from 'primevue/editor'
export default {
  components: { Editor },
  props: {
    value: String,
    height: {
      type: Number,
      default: 250,
      required: false
    }
  },
  data () {
    return {
      text: this.value
    }
  },
  watch: {
    text: function () {
      this.$emit('change', this.text)
    }
  },
  mounted () {
    setTimeout(() => {
      document.activeElement.blur()
      window.scrollTo(0, 0)
    }, 0)
  },
  methods: {}
}
</script>

<style>
.ql-editor {
  font-size: 16px;
}
</style>
