import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'
import { StorageService } from './storage.service'
import storageConstants from '../constants/storage.constants'

const DocumentService = {
  sendAsMail: async function (documentUuid, email, router) {
    try {
      StorageService.save(storageConstants.Email, email)
      return await ApiService.post(`/document/${documentUuid}/send-mail`, {
        email: email
      }).then(res => {
        return res
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      if (error.response.status === StatusCodes.PAYMENT_REQUIRED) {
        router.push({ name: 'SubscriptionCheckout' })
      }
    }
  },
  preview: async function (documentUuid) {
    try {
      return await ApiService.get(`/document/${documentUuid}/preview`)
        .then(res => {
          return res.data
        })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  }
}

export default DocumentService
