<template>
  <v-app>
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
                           indeterminate
                           :size="70"
                           :width="7"
                           color="primary"
      ></v-progress-circular>
    </div>
    <v-container>
      <LogoRow/>
      <v-row>
        <v-col class="tw-text-center">
        </v-col>
      </v-row>
      <v-row class="tw-mt-10">
        <v-col cols="4">
          <div class="tw-text-center tw-pt-3">
            <h2 >Bestellübersicht</h2>
            <div class="tw-flex tw-items-center tw-justify-center tw-mt-10">
              <ul class="tw-list-disc tw-pl-5 tw-mt-2 tw-text-left" style="margin-left: 20px">
                <li class="tw-font-bold tw-text-xl">Unbegrenzte Downloads</li>
                <li class="tw-font-bold tw-text-xl">Monatlich kündbar</li>
                <li class="tw-font-bold tw-text-xl">Online-Zahlung ohne Gebühren</li>
                <li class="tw-font-bold tw-text-xl">Schnelle Bearbeitung</li>
                <li class="tw-font-bold tw-text-xl">24/7 Kundenservice</li>
              </ul>

            </div>

          </div>
        </v-col>
        <v-col class="tw-text-center" cols="4">
          <div class="fox-payment-gate tw-p-3 tw-rounded-3xl">
            <h2>Jetzt Abo Zahlen</h2>
            <div class="tw-mt-10">
              <SubscriptionEmailCapture @subscribe="subscribe"/>
            </div>
            <p class="tw-text-2xl tw-mt-5">
              Betrag: 8,99 €
            </p>
          </div>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>

    <SidebarImage/>
  </v-app>
</template>
<script>
import LogoRow from '@/components/Common/LogoRow'
import SubscriptionEmailCapture from '@/views/Subscription/SubscriptionEmailCapture'
import ApiService from '@/services/api.service'
import { loadStripe } from '@stripe/stripe-js'
import SidebarImage from '@/components/SideBar/SidebarImage.vue'

export default {
  components: { SubscriptionEmailCapture, LogoRow, SidebarImage },
  data: function () {
    return {
      loading: false,
      envelope: null,
      showPaymentButtons: false,
      stripe: null
    }
  },
  async mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
  },

  methods: {
    async subscribe (email) {
      try {
        const response = await ApiService.post('/subscription/session', {
            email: email
          }
        )

        const { error } = await this.stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        if (error) {
          console.error('Stripe checkout error', error)
        }
      } catch (error) {
        console.error('Error creating subscription session', error)
      }
    }
  }
}
</script>
<style scoped>
>>> .fox-logo-row {
  margin-left: 30%;
}
</style>
