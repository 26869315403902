<template>
  <v-progress-linear
    indeterminate
    color="green"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
