<template>
  <v-dialog
    v-model="localShow"
    width="500"
    @input="handleInput"
  >
    <v-card
      elevation="2"
      :loading="loadingColor"
    >
      <v-card-title class="text-h5 lighten-2">
        Jetzt Dokument empfangen
      </v-card-title>
      <v-card-text>
        <p class="tw-text-xl">
          Das Dokument ist jetzt bereit für dich. Gebe deine E-Mail ein damit wir es dir schicken können!
        </p>
        <v-text-field
          :error-messages="emailErrorMessages"
          :autofocus="true"
          v-model="email"/>
        <v-checkbox
          v-model="newsletterConsent"
          label="Ich akzeptiere die hier angeführten Datenschutzbestimmungen zum Newsletterempfang."
          color="rgba(255,77,0,0.9)"
          hide-details
        ></v-checkbox>
        <p class="tw-mt-1 tw-text-xs">
          Wenn Sie das Formular absenden, erklären Sie sich damit einverstanden, dass Ihre persönlichen Daten (E-Mail)
          zum Zweck der Zusendung eines E-Mail-Newsletters mit Informationen über die Angebote von Rechnungsfuchs.com
          und neuen Funktionalität verwendet werden. Ihre Daten werden ausschließlich zur Versendung des Newsletters
          verwendet und nur an unseren E-mail-Marketing-Dienstleister weitergegeben. Sie können Ihre Einwilligung
          jederzeit schriftlich widerrufen, indem Sie ein E-Mail an info@rechnungsfuchs.com schreiben.
        </p>
      </v-card-text>
      <v-card-actions class="tw-flex tw-justify-end">
        <v-btn
          class="tw-text-white"
          elevation="2"
          color="rgba(255,77,0,0.9)"
          @click="submit"
        >
          Absenden
          <v-icon right>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    email: null,
    newsletterConsent: true,
    localShow: false
  }),
  watch: {
    show (newVal) {
      this.localShow = newVal
    }
  },
  validations () {
    return {
      email: { email, required }
    }
  },
  computed: {
    emailErrorMessages () {
      if (this.v$.email.$error && this.v$.email.$silentErrors.length > 0) {
        return ['Die E-Mail hat ein ungültiges format.']
      }
      return []
    },
    loadingColor () {
      return this.loading ? 'rgba(255,77,0,0.9)' : '#fff'
    }
  },
  methods: {
    async submit () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('submit',
          {
            email: this.email,
            newsletterConsent: this.newsletterConsent
          })
      }
    },
    handleClose () {
      this.localShow = false
      this.$emit('close')
    },
    handleInput (value) {
      this.localShow = value
      if (!value) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style>

</style>
