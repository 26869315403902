<template>
  <div>
    <div class="tw-bg-white tw-mt-5 tw-h-52 min-w-72 tw-w-auto tw-inline-block tw-border-2 tw-border-black tw-p-5">
      <div class="tw-text-xs tw-h-5">
        {{ envelope.senderName}}
        <span v-if="isPartOneSet">
        &#x2022;
      </span>
        {{ envelope.senderStreet }}
        <span v-if="isPartTwoSet">
        &#x2022;
      </span>
        {{ envelope.senderZipCode }}
        {{ envelope.senderCity }}
      </div>
      <div class="tw-mt-5">
        <div class="tw-h-6">
          {{ envelope.receiverName }}
        </div>
        <div v-if="envelope.receiverAddressAddition" class="tw-h-6">
          {{ envelope.receiverAddressAddition }}
        </div>
        <div class="tw-h-6">
          {{ envelope.receiverStreet }}
        </div>
        <div class="tw-h-6">
          {{ envelope.receiverZipCode }} {{ envelope.receiverCity }}
        </div>
        <div v-if="envelope.receiverAddition" class="tw-h-6">
          {{ envelope.receiverAddition }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    envelope: Object
  },
  computed: {
    isPartOneSet () {
      return this.envelope.senderName && (this.envelope.senderStreet || this.envelope.senderZipCode || this.envelope.senderCity)
    },
    isPartTwoSet () {
      return this.envelope.senderStreet && (this.envelope.senderZipCode || this.envelope.senderCity)
    }
  }
}
</script>

<style>
</style>
