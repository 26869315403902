<template>
  <v-app>
    <div class="fox-document-preview__container tw-min-h-screen">
      <div class="fox-loading-spinner">
        <v-progress-circular v-show="loading"
                             indeterminate
                             :size="70"
                             :width="7"
                             color="primary"
        ></v-progress-circular>
      </div>
      <div class="fox-document-preview__image-container">
        <div v-for="(uri, index) in imageUris" :key="index">
          <img :src="uri"
               alt=""/>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import DocumentService from '@/services/document.service'

export default {
  name: 'DocumentPreview',
  props: {
    documentUuid: String
  },
  data: function () {
    return {
      imageUris: [],
      loading: false
    }
  },
  async mounted () {
    this.loading = true
    const data = await DocumentService.preview(this.documentUuid)
    this.loading = false
    this.imageUris = data.uris
  }
}
</script>
