<template>
  <div class="mr-10">
    <label class="fox-label--simple">
      Rechnungssteller <span class="fox-input__require-star">*</span>
    </label>
    <div>
      <input type="text"
        v-model="biller.company"
        class="fox-input--simple tw-w-full"
        :placeholder="placeholder.company"
        v-bind:class="classes.company"
      >
    </div>
    <div class="tw-mt-2">
      <input type="text"
             v-model="biller.name"
             class="fox-input--simple tw-w-full"
             :placeholder="placeholder.name"
             v-bind:class="classes.name"
      >
    </div>
    <div class="tw-mt-2">
      <input type="text"
             v-model="biller.street"
             class="fox-input--simple tw-w-full"
             :placeholder="placeholder.street"
             v-bind:class="classes.street"
      >
    </div>
    <div class="tw-mt-2 tw-flex">
      <input type="text"
             v-model="biller.postalCode"
             class="fox-input--simple tw-w-full mr-2"
             :placeholder="placeholder.postalCode"
             v-bind:class="classes.postalCode"
      >
      <input type="text"
             v-model="biller.city"
             class="fox-input--simple tw-w-full"
             :placeholder="placeholder.city"
             v-bind:class="classes.city"
      >
    </div>
    <div class="tw-mt-2">
      <input type="text"
        v-model="biller.country"
        class="fox-input--simple tw-w-full"
        :placeholder="placeholder.country"
        v-bind:class="classes.country"
      >
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    biller: {
      type: Object
    }
  },
  data: function () {
    return {
      placeholder: {
        company: 'Unternehmen / Rechtsform',
        name: 'Name',
        street: 'Straße und Hausnummer',
        postalCode: 'PLZ',
        city: 'Stadt',
        country: 'Land'
      }
    }
  },
  validations () {
    return {
      biller: {
        name: { required },
        street: { required },
        postalCode: { required },
        city: { required },
        country: { required }
      }
    }
  },
  computed: {
    classes: function () {
      return {
        name: {
          'fox-input--error': this.v$.biller.name.$error
        },
        street: {
          'fox-input--error': this.v$.biller.street.$error
        },
        postalCode: {
          'fox-input--error': this.v$.biller.postalCode.$error
        },
        city: {
          'fox-input--error': this.v$.biller.city.$error
        },
        country: {
          'fox-input--error': this.v$.biller.country.$error
        }
      }
    }
  },
  methods: {
    updateBiller () {
      this.$emit('change', this.biller)
    }
  },
  watch: {
  }
}
</script>
