<template>
  <svg style="width: 20px; margin-right: 8px;" viewBox="-1.5 -1.5 18.00 18.00" fill="none" xmlns="http://www.w3.org/2000/svg">

    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(1.0499999999999998,1.0499999999999998), scale(0.86)">

    </g>

    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

    <g id="SVGRepo_iconCarrier"> <path d="M3.5 8H3V7H3.5C3.77614 7 4 7.22386 4 7.5C4 7.77614 3.77614 8 3.5 8Z" fill="#FFFFFF"/> <path d="M7 10V7H7.5C7.77614 7 8 7.22386 8 7.5V9.5C8 9.77614 7.77614 10 7.5 10H7Z" fill="#FFFFFF"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1.5C1 0.671573 1.67157 0 2.5 0H10.7071L14 3.29289V13.5C14 14.3284 13.3284 15 12.5 15H2.5C1.67157 15 1 14.3284 1 13.5V1.5ZM3.5 6H2V11H3V9H3.5C4.32843 9 5 8.32843 5 7.5C5 6.67157 4.32843 6 3.5 6ZM7.5 6H6V11H7.5C8.32843 11 9 10.3284 9 9.5V7.5C9 6.67157 8.32843 6 7.5 6ZM10 11V6H13V7H11V8H12V9H11V11H10Z" fill="#FFFFFF"/> </g>

  </svg>
</template>

<script>
export default {
  name: 'FocusIcon'
}
</script>
