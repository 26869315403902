import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'
import { EnvelopeDataFactory } from '@/factories/envelope.factory'

const EnvelopeService = {
    create: async function (documentUuid, envelope) {
        try {
            const data = EnvelopeDataFactory.formDataToRequest(documentUuid, envelope)

            return await ApiService.post(
                '/envelope',
                data
            ).then(res => {
                return res.data.uuid
            })
        } catch (error) {
            if (error.response.status === StatusCodes.BAD_REQUEST) {
                throw new BadRequestException(error)
            }

            throw error
        }
    },
    read: async function (envelopeUuid) {
        try {
            const response = await ApiService.get(`/envelope/${envelopeUuid}`)
            return EnvelopeDataFactory.requestToRawEnvelope(response.data)
        } catch (error) {
            if (error.response.status === StatusCodes.BAD_REQUEST) {
                throw new BadRequestException(error)
            }

            throw error
        }
    }
}

export default EnvelopeService
