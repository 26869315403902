<template>
  <v-app>
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
                           indeterminate
                           :size="70"
                           :width="7"
                           color="primary"
      ></v-progress-circular>
    </div>
    <v-container>
      <LogoRow/>
      <v-row>
        <v-col class="tw-text-center">
          <h1>CHECKOUT</h1>
        </v-col>
      </v-row>
      <v-row class="tw-mt-10">
        <v-spacer/>
        <v-col cols="4">
          <div class="tw-text-center tw-pt-3">
            <h2>Bestellübersicht</h2>
            <div class="tw-flex tw-items-center tw-justify-center tw-mt-10">
              <h3 class="tw-font-bold tw-mr-2">
                Ihr Dokument
              </h3>
              <v-icon
                large
                color="rgba(255,77,0,0.9)"
                @click="previewDocument"
                :disabled="!envelope"
              >
                mdi-eye
              </v-icon>
            </div>
            <h3 class="tw-mt-10 tw-font-bold">Zieladresse</h3>
            <div v-if="envelope">
              <div class="tw-mt-5">
                <div class="tw-h-6">
                  {{ envelope.addressLine1 }}
                </div>
                <div class="tw-h-6">
                  {{ envelope.addressLine2 }}
                </div>
                <div class="tw-h-6">
                  {{ envelope.addressLine3 }}
                </div>
                <div v-if="envelope.addressLine4" class="tw-h-6">
                  {{ envelope.addressLine4 }}
                </div>
                <div v-if="envelope.addressLine5" class="tw-h-6">
                  {{ envelope.addressLine5 }}
                </div>
              </div>
            </div>
            <img
              class="deutschepost__logo tw-mt-5"
              :src="require('@/assets/images/1024px-Logo_Deutsche_Post_2019.svg_-min.png')"
              alt="Deutschepost Logo">
            <p class="tw-text-3xl tw-mt-5">
              Sicherer Versand via Deutsche Post
            </p>

          </div>
        </v-col>
        <v-col class="tw-text-center" cols="4">
          <div class="fox-payment-gate tw-p-3 tw-rounded-3xl">
            <h2>Jetzt Zahlen</h2>
            <div v-if="envelope" class="tw-mt-10">
              <EmailCapture @success="startPayment" :document-uuid="envelope.documentUuid"
                            v-show="!showPaymentButtons"/>
            </div>
            <p class="tw-text-2xl tw-mt-5">
              Betrag: 2,99 €
            </p>
          </div>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import EnvelopeService from '@/services/envelope.service'
import LogoRow from '@/components/Common/LogoRow'
import EmailCapture from '@/views/Payment/Stripe/EmailCapture'
import { loadStripe } from '@stripe/stripe-js'
import ApiService from '@/services/api.service'

export default {
  components: { EmailCapture, LogoRow },
  props: {
    envelopeUuid: String
  },
  data: function () {
    return {
      loading: false,
      envelope: null,
      showPaymentButtons: false,
      stripe: null
    }
  },
  async mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })

    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_TOKEN)

    await this.$store.commit('checkoutEnvelope/setEnvelopeUuid', this.envelopeUuid)
    this.envelope = await EnvelopeService.read(this.envelopeUuid)
  },
  methods: {
    previewDocument () {
      if (this.envelope) {
        const route = this.$router.resolve({
          name: 'DocumentPreview',
          params: { documentUuid: this.envelope.documentUuid }
        })
        window.open(route.href)
      }
    },
    async startPayment (email) {
      try {
        const response = await ApiService.post('/payment/session', {
            email: email,
            envelopeUuid: this.envelopeUuid
          }
        )

        const { error } = await this.stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        if (error) {
          console.error('Stripe checkout error', error)
        }
      } catch (error) {
        console.error('Error creating subscription session', error)
      }
    }
  }
}
</script>
<style>

</style>
