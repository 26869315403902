<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item v-for="(item, index) in items" :key="index" :to="item.route">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="openCalculator">
          <v-list-item-action>
            <v-icon>mdi-calculator</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Taschenrechner
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item link href="https://rechnungsfuchs.com/" target="_blank">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Hauptseite
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="https://rechnungsfuchs.com/blog/" target="_blank">
          <v-list-item-action>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Anleitung
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="https://rechnungsfuchs.com/faq/" target="_blank">
          <v-list-item-action>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              FAQ
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="tw-ml-3 tw-flex tw-items-center tw-h-full tw-pl-0">
        <img
          class="tw-h-10"
          :src="require('@/assets/images/logo-lean.png')"
          alt="Rechnungsfuchs"/>
        <h1 class="tw-ml-3">{{this.$attrs.siteTitle}}</h1>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer app>
      <a href="https://rechnungsfuchs.com/impressum/" target="_blank" class="tw-mr-5">Impressum</a>
      <a href="https://rechnungsfuchs.com/datenschutz/" target="_blank" class="tw-mr-5">Datenschutz</a>
      <span>&copy; 2023</span>
      <v-spacer></v-spacer>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: 'DashboardLayout',
  computed: {},
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Rechnung', icon: 'mdi-file', route: '/dashboard/invoice' },
        { title: 'Brief', icon: 'mdi-email-outline', route: '/dashboard/letter' }
      ],
      mini: true
    }
  },
  methods: {
    openCalculator () {
      const windowFeatures = 'left=700,top=200,width=370,height=500'
      window.open('https://fuchsrechner.netlify.app/', 'popoup', windowFeatures)
    }
  }
}
</script>
