<template>
  <div>
    <EmailCaptureDialog
      :show="showCapture"
      @submit="submit"
      :loading="loadingCapture"
      @close="handleDialogClose"/>
    <v-snackbar
      v-model="showMessage"
      :top="true">
      E-Mail erfolgreich versendet.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="showMessage = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import EmailCaptureDialog from '@/components/Common/SendAsEmail/EmailCaptureDialog'

export default {
  components: { EmailCaptureDialog },
  props: {
    showCapture: {
      type: Boolean,
      required: true
    },
    loadingCapture: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMessage: false
    }
  },
  methods: {
    async submit ({ email, newsletterConsent }) {
      await this.$emit('submit', {
        email,
        newsletterConsent
      })
      this.showMessage = true
    },
    handleDialogClose () {
      this.$emit('update:showCapture', false)
    }
  }
}
</script>
