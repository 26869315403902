import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'
import Store from '@/stores'

const PaypalService = {
  generateClientToken: async function () {
    try {
      return await ApiService.get('/payment/paypal/client-token')
        .then(res => {
          return res.client_token
        })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  createOrder: async function () {
    const envelopeUuid = Store.getters['checkoutEnvelope/getEnvelopeUuid']
    try {
      return await ApiService.post('/payment/paypal/orders', {
        envelope_uuid: envelopeUuid
      })
        .then(res => {
          return res.data.order_id
        })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  captureOrder: async function (orderId) {
    try {
      return await ApiService.post(`/payment/paypal/orders/${orderId}/capture`)
        .then(res => {
          return res
        })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  }
}

export { PaypalService }
