<template>
  <v-app>
    <v-container>
      <LogoRow/>
      <div class="tw-text-black confirmation__container tw-text-center">
        <h1 class="tw-text-black">Vielen Dank für ihren Auftrag!</h1>
        <p>
          In kurze erhalten sie eine Bestätigungs E-Mail.
        </p>
      </div>
    </v-container>
  </v-app>
</template>
<script>

import LogoRow from '@/components/Common/LogoRow'

export default {
  components: { LogoRow },
  data () {
    return {}
  },
  methods: {},
  mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })
  }
}
</script>
<style>

</style>
