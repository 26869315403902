import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'

const LetterService = {
  createLetter: async function (data) {
    try {
      return await ApiService.post('/letters', {
          data: this.prepareLetterData(data)
        }
      ).then(res => {
        return res.data.document_uuid
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  readLetterDocument: async function (documentUuid) {
    try {
      return await ApiService.get(`/document/${documentUuid}`,
        {
          responseType: 'blob'
        }
      ).then(res => {
        return new Blob(
          [res.data],
          { type: 'application/pdf' })
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  prepareLetterData (data) {
    return {
      sender_name: data.sender.name,
      sender_street: data.sender.street,
      sender_postal_code: data.sender.postalCode,
      sender_city: data.sender.city,
      sender_country_code: 'de',
      recipient: data.recipient,
      extra_info: data.extraInfo,
      title: data.title,
      text: data.text,
      footer_field_one: data.footerFieldOne,
      footer_field_two: data.footerFieldTwo,
      footer_field_three: data.footerFieldThree
    }
  }
}

export default LetterService
