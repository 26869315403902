<template>
  <div class="tw-text-center">
    <p>
      Gebe deine E-Mail ein um zu bezahlen.
    </p>
    <div class="tw-ml-auto tw-mr-auto">
      <div style="margin-top: 30px">
        <v-text-field
          label="E-Mail"
          dense
          v-model="email"
          :error="v$.email.$error"
        ></v-text-field>
      </div>
    </div>
    <v-btn
      class="tw-text-white"
      elevation="2"
      color="rgba(255,77,0,0.9)"
      @click="submit"
    >
      Fortfahren
    </v-btn>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { StorageService } from '@/services/storage.service'
import StorageConstants from '@/constants/storage.constants'

export default {
  data: function () {
    return {
      email: null
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      email: { email, required }
    }
  },
  computed: {
    emailErrorMessages () {
      if (this.v$.email.$silentErrors.length > 0) {
        return ['Die E-Mail hat ein ungültiges format.']
      }
      return []
    }
  },
  mounted () {
    const storedEmail = StorageService.get(StorageConstants.Email)
    this.email = storedEmail || ''
  },
  methods: {
    async submit () {
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.$emit('subscribe', this.email)
        }
      }
    }
}
</script>

<style scoped>
.v-input__control{
  margin-top: 20px;
}
</style>
