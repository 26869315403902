<template>
  <v-app>
    <div class="main-content tw-min-h-screen">
      <v-container>
        <LogoRow/>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <h2>Empfänger</h2>
            <p class="tw-mt-3">
              Bitte gib nun die Zustellungs-Informationen für deinen Brief an.
            </p>
            <div class="tw-mt-7">
              <v-text-field
                label="Name oder Unternehmen *"
                placeholder="z. B. Max Mustermann oder Muster GmbH"
                :persistent-placeholder="true"
                dense
                v-model="envelope.receiverName"
                :error="v$.envelope.receiverName.$error"
              ></v-text-field>
              <v-text-field
                label="Addresszusatz"
                placeholder="z.B. Tim Myers"
                :persistent-placeholder="true"
                dense
                v-model="envelope.receiverAddressAddition"
              ></v-text-field>
              <v-text-field
                label="Straße und Hausnummer *"
                placeholder="z. B. Musterstraße 29"
                :persistent-placeholder="true"
                dense
                v-model="envelope.receiverStreet"
                :error="v$.envelope.receiverStreet.$error"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Postleitzahl *"
                    dense
                    v-model="envelope.receiverZipCode"
                    :error="v$.envelope.receiverZipCode.$error"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Stadt *"
                    dense
                    v-model="envelope.receiverCity"
                    :error="v$.envelope.receiverCity.$error"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <vue-country-code
                    :enableSearchField="true"
                    :v-model="envelope.receiverCountryCode"
                    @onSelect="selectCountryCode"
                    defaultCountry="de"
                    :onlyCountries="['de']"
                    :preferredCountries="['de']">
                  </vue-country-code>
                </v-col>
              </v-row>
            </div>
            <v-switch
              v-model="showSenderAddressInput"
              label="Absender hinzufügen (optional)"
              color="orange"
              value="orange"
              hide-details
            ></v-switch>
            <div v-if="showSenderAddressInput" class="tw-mt-5">
              <h2>Absender</h2>
              <div class="tw-mt-5">
                <v-text-field
                  label="Name order Unternehmen"
                  dense
                  v-model="envelope.senderName"
                  :error="v$.envelope.senderName.$error"
                ></v-text-field>
                <v-text-field
                  label="Straße und Hausnummer"
                  dense
                  v-model="envelope.senderStreet"
                  :error="v$.envelope.senderStreet.$error"
                ></v-text-field>
                <v-text-field
                  label="Postleitzahl"
                  dense
                  v-model="envelope.senderZipCode"
                  :error="v$.envelope.senderZipCode.$error"
                ></v-text-field>
                <v-text-field
                  label="Stadt"
                  dense
                  v-model="envelope.senderCity"
                  :error="v$.envelope.senderCity.$error"
                ></v-text-field>
              </div>
            </div>
            <div class="tw-mt-5">
              <v-btn
                class="tw-text-white"
                elevation="2"
                :right="true"
                color="rgba(255,77,0,0.9)"
                :disabled="v$.$invalid"
                @click="submit"
              >
                Weiter
              </v-btn>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5">
            <div class="tw-flex tw-content-start tw-items-center">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <h2 class="tw-mr-5">Adresskopfvorschau</h2>
                  <v-icon
                    v-bind="attrs"
                    v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span>Der Adresskopf wird auf das von uns generierte Deckblatt gedruckt. Dein Dokument ist davon nicht betroffen.</span>
              </v-tooltip>
            </div>
            <EnvelopeAddressPreview :envelope="envelope"/>
            <div class="tw-mt-5 tw-flex tw-items-center">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <h2 class="tw-mr-5">Dokument-Vorschau</h2>
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="rgba(255,77,0,0.9)"
                    @click="previewDocument"
                    :disabled="!envelope"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Hier kannst du nochmal dein Dokument überprüfen.</span>
              </v-tooltip>

            </div>
            <div class="tw-mt-5 preview-container">
              <div v-for="(uri, index) in imageUris" :key="index" class="tw-bg-white">
                <img :src="uri"
                     alt=""/>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import EnvelopeAddressPreview from '@/components/Envelope/EnvelopeAddressPreview'
import envelopeTestingData from '@/test/data/envelopeData.json'
import DocumentService from '@/services/document.service'
import LogoRow from '@/components/Common/LogoRow'

export default {
  components: { LogoRow, EnvelopeAddressPreview },
  props: {
    documentUuid: String
  },
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      showSenderAddressInput: false,
      imageUris: [],
      envelope: {
        receiverName: null,
        receiverAddressAddition: null,
        receiverStreet: null,
        receiverZipCode: null,
        receiverCity: null,
        receiverCountryCode: 'de',
        receiverAddition: null,
        senderName: null,
        senderStreet: null,
        senderZipCode: null,
        senderCity: null
      }
    }
  },
  async mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.envelope = envelopeTestingData
    }
    const data = await DocumentService.preview(this.documentUuid)
    this.imageUris = data.uris
  },
  computed: {
    senderRequired () {
      return false
    }
  },
  validations () {
    let validations = {
      receiverName: {
        required,
        maxLengthValue: maxLength(191)
      },
      receiverStreet: {
        required,
        maxLengthValue: maxLength(191)
      },
      receiverZipCode: {
        required,
        maxLengthValue: maxLength(30)
      },
      receiverCity: {
        required,
        maxLengthValue: maxLength(191)
      },
      receiverCountryCode: { required },
      senderName: {},
      senderStreet: {},
      senderZipCode: {},
      senderCity: {}
    }

    if (this.showSenderAddressInput) {
      validations = {
        receiverName: {
          required,
          maxLengthValue: maxLength(191)
        },
        receiverStreet: {
          required,
          maxLengthValue: maxLength(191)
        },
        receiverZipCode: {
          required,
          maxLengthValue: maxLength(30)
        },
        receiverCity: {
          required,
          maxLengthValue: maxLength(191)
        },
        receiverCountryCode: { required },
        senderName: {
          required,
          maxLengthValue: maxLength(191)
        },
        senderStreet: {
          required,
          maxLengthValue: maxLength(191)
        },
        senderZipCode: {
          required,
          maxLengthValue: maxLength(30)
        },
        senderCity: {
          required,
          maxLengthValue: maxLength(191)
        }
      }
    }

    return {
      envelope: validations
    }
  },
  methods: {
    async submit () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('submit', this.envelope)
      }
    },
    previewDocument () {
      if (this.documentUuid) {
        const route = this.$router.resolve({
          name: 'DocumentPreview',
          params: { documentUuid: this.documentUuid }
        })
        window.open(route.href)
      }
    },
    selectCountryCode ({ iso2 }) {
      this.envelope.receiverCountryCode = iso2
    }
  }
}
</script>

<style>

</style>
