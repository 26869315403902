<template>
  <div class="fox-invoice-create__wrapper">
    <div class="fox-invoice-create__container">
      <div class="fox-invoice-action__container tw-mb-10" style="display: flex; align-items: center;">
        <a href="#" @click.prevent="example" class="link-button" style="margin-right: auto;">
          Beispiel Brief
        </a>
        <button @click="preview" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--sky-blue tw-mr-6 tw-flex tw-items-center tw-shadow-lg"
        >
          <FocusIcon />
          <span style="color: black; font-weight: bold">Vorschau</span>
        </button>
        <button @click="sendAsEnvelop" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--green tw-mr-6 tw-flex tw-items-center">
          <LetterIcon />
          <span style="color: white;">als Brief Versenden</span>
        </button>
        <button @click="download" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--default tw-mr-6">
          <PdfIcon />
          <span style="color: white;">Download - PDF</span>
        </button>
      </div>
      <div class="tw-grid tw-grid-cols-2">
        <div>
          <LetterSenderForm :sender="letter.sender" @onchange="updateSender"/>
          <div class="tw-mt-5">
            <label class="fox-label--simple">
              Empfänger <span class="fox-input__require-star">*</span>
            </label>
            <textarea name="customer_address_plain"
                      rows="5"
                      class="fox-input--simple"
                      :placeholder=placeholder.recipient
                      v-bind:class="classes.recipient"
                      v-model="letter.recipient"></textarea>
          </div>
        </div>
        <div>
          <div class="letter-sender-address tw-ml-auto">
            <label class="fox-label--simple">
              Zusatzinformation
            </label>
            <textarea name="customer_address_plain"
                      rows="5"
                      class="fox-input--simple"
                      :placeholder=placeholder.extraInfo
                      v-model="letter.extraInfo"></textarea>
          </div>
        </div>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
        <div>
          <label class="fox-label--simple">
            Title<span class="fox-input__require-star">*</span>
          </label>
          <input type="text"
                 class="fox-input--simple tw-w-full"
                 v-model="letter.title"
                 :placeholder="placeholder.title"
                 v-bind:class="classes.title"
          >
        </div>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div>
        <InvoiceRichTextInput @change="updateLetterText" :value="letter.text" :height="320"/>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-6">
        <div>
          <label class="fox-label--simple">
            Fusszeile 1
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.footerFieldOne"
                    class="fox-input--simple tw-w-full"
                    v-model="letter.footerFieldOne"></textarea>
        </div>
        <div>
          <label class="fox-label--simple">
            Fusszeile 2
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.footerFieldTwo"
                    class="fox-input--simple tw-w-full"
                    v-model="letter.footerFieldTwo"></textarea>
        </div>
        <div>
          <label class="fox-label--simple">
            Fusszeile 3
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.footerFieldThree"
                    class="fox-input--simple tw-w-full"
                    v-model="letter.footerFieldThree"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import $ from 'jquery'
import { required } from '@vuelidate/validators'
import InvoiceRichTextInput from '@/components/Invoice/Create/InvoiceRichTextInput'
import letterTestingData from '@/test/data/letterData.json'
import LetterSenderForm from '@/components/Letter/Create/LetterSenderForm'
import LetterIcon from '@/components/Icon/LetterIcon.vue'
import PdfIcon from '@/components/Icon/PdfIcon.vue'
import FocusIcon from '@/components/Icon/FocusIcon.vue'

export default {
  components: { LetterSenderForm, InvoiceRichTextInput, LetterIcon, PdfIcon, FocusIcon },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      letter: {
        text: { required },
        recipient: { required },
        title: { required }
      }
    }
  },
  data: function () {
    return {
      letter: {
        sender: {
          name: null,
          street: null,
          postalCode: null,
          city: null
        },
        text: null,
        recipient: null,
        extraInfo: null,
        title: null,
        footerFieldOne: null,
        footerFieldTwo: null,
        footerFieldThree: null
      },
      placeholder: {
        text: '',
        recipient: 'Muster GmbH\nMusterstraße 62-66\n80636 München\nDeutschland',
        extraInfo: 'Datum: 11. Nov 2022\nAnsprechpartner: Dirk Heller\nTel: 151 5233 2132',
        title: 'Geschäftsbrief',
        footerFieldOne: 'Tel.: 154 1232 4923\nE-Mail: contact@max-mustermann.com\nWeb: www.max-mustermann.de',
        footerFieldTwo: 'USt.-ID: DE12345893\nInhaber/-in: Max Musterman',
        footerFieldThree: 'Berliner Volksbank\nIBAN: DE91100000000123456789\nBIC: BEVODEBB'
      }
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.letter = letterTestingData
    }
  },
  computed: {
    classes: function () {
      return {
        text: {
          'fox-input--error': this.v$.letter.text.$error
        },
        recipient: {
          'fox-input--error': this.v$.letter.recipient.$error
        },
        title: {
          'fox-input--error': this.v$.letter.title.$error
        }
      }
    }
  },
  methods: {
    async preview () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('preview', this.letter)
      } else {
        this.scrollToFirstError()
      }
    },
    async example () {
      this.$emit('example')
    },
    async download () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('download', this.letter)
      } else {
        this.scrollToFirstError()
      }
    },
    async sendAsEnvelop () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('sendAsEnvelope', this.letter)
      } else {
        this.scrollToFirstError()
      }
    },
    updateLetterText (text) {
      this.letter.text = text
    },
    updateSender (sender) {
      this.letter.sender = sender
    },
    scrollToFirstError () {
      $('html, body').animate({
        scrollTop: $('.fox-input--error:visible:first').offset().top
      }, 800)
    }
  }
}
</script>
