<template>
  <div class="fox-invoice-create__wrapper">
    <div class="fox-invoice-create__container">
      <div class="tw-mb-6" style="display: flex; align-items: center;">
        <a href="#" @click.prevent="invoiceExample" class="link-button" style="margin-right: auto;">
          Beispiel Rechnung
        </a>
        <div class="fox-invoice-action__container">
          <button @click="preview" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--sky-blue tw-mr-6 tw-flex tw-items-center tw-shadow-lg"
          >
            <FocusIcon />
            <span style="color: black; font-weight: bold">Vorschau</span>
          </button>
          <button @click="sendAsEnvelop" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--green tw-mr-6 tw-flex tw-items-center">
            <LetterIcon />
            <span style="color: white;">als Brief Versenden</span>
          </button>
          <button @click="downloadPdf" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--default tw-mr-6">
            <PdfIcon />
            <span style="color: white;">Download - PDF</span>
          </button>
        </div>
      </div>

      <div class="fox-invoice-action__container tw-mb-10">
        <button @click="downloadCombined" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--purple tw-mr-6">
          <DownloadIcon />
          <span style="color: black;">Download - ZUGFeRD</span>
        </button>
        <button @click="downloadXml" class="fox-invoice-action__create-btn fox-invoice-action__create-btn--purple tw-mr-6">
          <DownloadIcon />
          <span style="color: black;">Download - X-Rechnung</span>
        </button>
      </div>

      <!-- Main layout grid -->
      <div class="tw-grid tw-grid-cols-2 tw-gap-4">
        <!-- Left Column (Biller Form + Invoice Recipient) -->
        <div>
          <InvoiceBillerForm :biller="invoice.biller" @onchange="updateBiller"/>
          <div class="mt-5">
            <label class="fox-label--simple">
              Rechnungsempfänger <span class="fox-input__require-star">*</span>
            </label>
            <textarea name="customer_address_plain"
              rows="5"
              class="fox-input--simple"
              :placeholder=placeholder.customerAddressPlain
              v-bind:class="classes.customerAddressPlain"
              v-model="invoice.customerAddressPlain"></textarea>
          </div>

        </div>
        <div>
          <div class="tw-mb-8 tw-flex tw-items-center">
            <div class="tw-w-full tw-h-56 tw-rounded-lg tw-shadow-xl tw-bg-white tw-flex tw-items-center tw-justify-center"
              @click="triggerLogoFileInput"
            >
              <div class="tw-flex tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-10 tw-w-10 tw-mr-3 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v6h16v-6M12 3v13m0-13L5 10m7-7l7 7" />
                </svg>
                <p class="tw-text-gray-400 tw-font-bold tw-text-2xl tw-w-full tw-text-center" style="margin-bottom: 0">Logo hochladen</p>
              </div>
              <input
                type="file"
                ref="fileInput"
                @change="previewImage"
                style="display: none;"
                accept="image/*"
              />
              <div class="upload-content">
                <img
                  v-if="imagePreview"
                  :src="imagePreview"
                  alt="Uploaded Image"
                  class="uploaded-image"
                  style="max-width: 60%"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                <div>
                  <label class="fox-label--simple">
                    Betreff<span class="fox-input__require-star">*</span>
                  </label>
                  <input type="text"
                    class="fox-input--simple tw-w-full"
                    v-model="invoice.subject"
                    :placeholder="placeholder.subject"
                    v-bind:class="classes.subject"
                  >
                </div>
                <!-- Rechnungsnummer -->
                <div>
                  <label class="fox-label--simple">
                    Rechnungsnummer<span class="fox-input__require-star">*</span>
                  </label>
                  <input type="text"
                    v-model="invoice.invoiceNo"
                    class="fox-input--simple tw-w-full"
                    :placeholder="placeholder.invoiceNo"
                    v-bind:class="classes.invoiceNo"
                  >
                </div>
                <!-- Rechnungsdatum -->
                <div>
                  <label class="fox-label--simple">
                    Rechnungsdatum<span class="fox-input__require-star">*</span>
                  </label>
                  <date-picker
                    v-model="invoice.invoiceDate"
                    v-bind:class="classes.invoiceDate"
                    type="date"
                    format="DD.MM.YYYY"
                  />
                </div>
                <!-- Lieferungsdatum -->
                <div>
                  <label class="fox-label--simple">
                <span v-show="!isDeliveryDatepickerRange">
                  Lieferungsdatum<span class="fox-input__require-star">*</span>
                </span>
                    <span v-show="isDeliveryDatepickerRange">
                  Lieferzeitraum<span class="fox-input__require-star">*</span>
                </span>
                  </label>
                  <date-picker
                    v-model="invoice.invoiceDeliveryDate"
                    v-bind:class="classes.invoiceDeliveryDate"
                    @clear="clearDeliveryDate"
                    @calendar-change="clearDeliveryDate"
                    type="date"
                    :range="isDeliveryDatepickerRange"
                    format="DD.MM.YYYY"/>
                  <button
                    class="fox-invoice-button__blue-text"
                    v-show="isDeliveryDatepickerRange"
                    @click="toggleInvoiceDeliveryCalendar"
                  >
                    + Lieferungsdatum
                  </button>
                  <button
                    class="fox-invoice-button__blue-text"
                    v-show="!isDeliveryDatepickerRange"
                    @click="toggleInvoiceDeliveryCalendar"
                  >
                    + Lieferzeitraum
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fox-invoice-create__divider"></div>

      <div>
        <InvoiceRichTextInput @change="updateHeadText" :value="invoice.headText"/>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div>
        <InvoiceItemContainer @updateInvoiceItems="updateInvoiceItems" :initial-items="invoice.invoiceItems"/>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div>
        <InvoiceRichTextInput @change="updateBottomText" :value="invoice.bottomText"/>
      </div>
      <div class="fox-invoice-create__divider"></div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-6">
        <div>
          <label class="fox-label--simple">
            Kontakt
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.contactDataPlain"
                    class="fox-input--simple tw-w-full"
                    v-model="invoice.contactDataPlain"></textarea>
        </div>
        <div>
          <label class="fox-label--simple">
            Rechtliches
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.legalDetailsPlain"
                    class="fox-input--simple tw-w-full"
                    v-model="invoice.legalDetailsPlain"></textarea>
        </div>
        <div>
          <label class="fox-label--simple">
            Bankverbindung
          </label>
          <textarea name="customer_address_plain"
                    rows="5"
                    :placeholder="placeholder.bankDetailsPlain"
                    class="fox-input--simple tw-w-full"
                    v-model="invoice.bankDetailsPlain"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceItemContainer from '@/components/Invoice/Create/InvoiceItemContainer'
import DatePicker from 'vue2-datepicker'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import InvoiceRichTextInput from '@/components/Invoice/Create/InvoiceRichTextInput'
import $ from 'jquery'
import invoiceTestingData from '../../../test/data/invoiceData.json'
import InvoiceBillerForm from '@/components/Invoice/Create/InvoiceBillerForm'
import FocusIcon from '@/components/Icon/FocusIcon.vue'
import LetterIcon from '@/components/Icon/LetterIcon.vue'
import PdfIcon from '@/components/Icon/PdfIcon.vue'
import DownloadIcon from '@/components/Icon/DownloadIcon.vue'

export default {
  name: 'InvoiceForm',
  components: { InvoiceBillerForm, InvoiceRichTextInput, InvoiceItemContainer, DatePicker, FocusIcon, LetterIcon, PdfIcon, DownloadIcon },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      invoice: {
        customerAddressPlain: { required },
        subject: { required },
        invoiceNo: { required },
        invoiceDate: { required },
        invoiceDeliveryDate: { required }
      }
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.invoice = invoiceTestingData
    }
  },
  data: function () {
    return {
      invoice: {
        biller: {
          company: null,
          name: null,
          street: null,
          postalCode: null,
          city: null,
          country: null,
          logo: null
        },
        customerAddressPlain: null,
        customerCountryCode: 'de',
        subject: null,
        invoiceNo: null,
        invoiceDate: null,
        invoiceDeliveryDate: null,
        headText: '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>Sehr geehrte Damen und Herren,</p>\n<p>vielen Dank für Ihren Auftrag und das damit verbundene Vertrauen! Hiermit stelle ich Ihnen die folgenden Leistungen in Rechnung:</p>\n</div>\n</div>\n</div>',
        bottomText: '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>Bitte &uuml;berweisen Sie den Rechnungsbetrag unter Angabe der Rechnungsnummer auf das unten angegebene Konto.</p>\n<p>Der Rechnungsbetrag ist sofort f&auml;llig.</p>\n<p>&nbsp;</p>\n<p>Mit freundlichen Gr&uuml;&szlig;en</p>\n</div>\n</div>\n</div>',
        currency: 'EUR',
        contactDataPlain: null,
        legalDetailsPlain: null,
        bankDetailsPlain: null,
        logoFile: null,
        invoiceItems: [
          {
            position: 1,
            description: null,
            quantity: null,
            unit: null,
            unitPrice: null,
            vatPercentage: null
          }
        ]
      },
      isDeliveryDatepickerRange: false,
      placeholder: {
        customerAddressPlain: 'Muster GmbH\nMusterstraße 62-66\n80636 München\nDeutschland',
        subject: 'Rechnung RE-2022-08-01',
        invoiceNo: 'RE-2022-08-01',
        IBAN: 'DE17100500001258767',
        accountHolder: 'Max Mustermann',
        contactDataPlain: 'Tel.: 154 1232 4923\nE-Mail: contact@max-mustermann.com\nWeb: www.max-mustermann.de',
        legalDetailsPlain: 'USt.-ID: DE12345893\nInhaber/-in: Max Musterman',
        bankDetailsPlain: 'Berliner Volksbank\nIBAN: DE91100000000123456789\nBIC: BEVODEBB'
      },
      imagePreview: null
    }
  },
  computed: {
    classes: function () {
      return {
        customerAddressPlain: {
          'fox-input--error': this.v$.invoice.customerAddressPlain.$error
        },
        subject: {
          'fox-input--error': this.v$.invoice.subject.$error
        },
        invoiceNo: {
          'fox-input--error': this.v$.invoice.invoiceNo.$error
        },
        invoiceDate: {
          'fox-input--error': this.v$.invoice.invoiceDate.$error
        },
        invoiceDeliveryDate: {
          'fox-input--error': this.v$.invoice.invoiceDeliveryDate.$error,
          'mx-calendar-small-text': this.isDeliveryDatepickerRange
        }
      }
    }
  },
  methods: {
    updateInvoiceItems (items) {
      this.invoice.invoiceItems = items
    },
    updateBottomText (text) {
      this.invoice.bottomText = text
    },
    updateHeadText (text) {
      this.invoice.headText = text
    },
    updateBiller (biller) {
      this.invoice.biller = biller
    },
    clearDeliveryDate () {
      this.invoice.invoiceDeliveryDate = null
    },
    toggleInvoiceDeliveryCalendar () {
      this.isDeliveryDatepickerRange = !this.isDeliveryDatepickerRange
      this.invoice.invoiceDeliveryDate = null
    },
    triggerLogoFileInput () {
      this.$refs.fileInput.click()
    },
    previewImage (event) {
      const file = event.target.files[0]
      console.log(file)
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result
        }
        reader.readAsDataURL(file)
        this.invoice.logoFile = file
      }
    },
    async invoiceExample () {
      this.$emit('invoiceExample')
    },
    async preview () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('preview', this.invoice)
      } else {
        this.scrollToFirstError()
      }
    },
    async downloadPdf () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('downloadPdf', this.invoice)
      } else {
        this.scrollToFirstError()
      }
    },
    async downloadXml () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('downloadXml', this.invoice)
      } else {
        this.scrollToFirstError()
      }
    },
    async downloadCombined () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('downloadCombined', this.invoice)
      } else {
        this.scrollToFirstError()
      }
    },
    async sendAsEnvelop () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.$emit('sendAsEnvelope', this.invoice)
      } else {
        this.scrollToFirstError()
      }
    },
    scrollToFirstError () {
      $('html, body').animate({
        scrollTop: $('.fox-input--error:visible:first').offset().top - 100
      }, 800)
    }
  }
}
</script>
