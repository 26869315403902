<template>
  <v-app class="tw-relative">
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
                           indeterminate
                           :size="70"
                           :width="7"
                           color="primary"
      ></v-progress-circular>
    </div>
    <InvoiceForm @invoiceExample="invoiceExample" @preview="previewInvoice" @downloadPdf="downloadPdf"
                 @downloadXml="downloadXml" @downloadCombined="downloadCombined"
                 @sendAsEnvelope="sendAsEnvelope"/>
    <SendAsEmail @submit="submitDownload" :show-snack="showSnack" :showCapture="showCapture"
                 :loading-capture="loadingCapture" @update:showCapture="showCapture = $event"/>

    <SidebarImage/>
  </v-app>
</template>

<script>

import InvoiceForm from '@/components/Invoice/Create/InvoiceForm'
import InvoiceService from '@/services/invoice.service'
import DocumentService from '@/services/document.service'

import UserService from '@/services/user.service'
import SendAsEmail from '@/components/Common/SendAsEmail/SendAsEmail'
import SidebarImage from '@/components/SideBar/SidebarImage.vue'

const VUE_APP_BACKEND_API_URL = process.env.VUE_APP_BACKEND_API_URL

export default {
  name: 'InvoiceCreate',
  components: { SendAsEmail, InvoiceForm, SidebarImage },
  data: function () {
    return {
      loading: false,
      showCapture: false,
      documentUuid: null,
      loadingCapture: false,
      showSnack: false
    }
  },
  mounted () {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1455597 })
  },
  methods: {
    async invoiceExample () {
      window.open(`${VUE_APP_BACKEND_API_URL}/pdf/examples/invoice.pdf`)
    },
    async previewInvoice (invoice) {
      this.loading = true
      const invoiceDocumentUuid = await InvoiceService.createInvoice(invoice)
      this.loading = false

      const route = this.$router.resolve({ name: 'DocumentPreview', params: { documentUuid: invoiceDocumentUuid } })
      window.open(route.href)
    },
    async downloadPdf (invoice) {
      this.loading = true
      invoice.invoiceType = 'pdf'
      this.documentUuid = await InvoiceService.createInvoice(invoice)
      this.loading = false
      this.showCapture = true
    },
    async downloadXml (invoice) {
      this.loading = true
      invoice.invoiceType = 'xml'
      this.documentUuid = await InvoiceService.createInvoice(invoice)
      this.loading = false
      this.showCapture = true
    },
    async downloadCombined (invoice) {
      this.loading = true
      invoice.invoiceType = 'combined'
      this.documentUuid = await InvoiceService.createInvoice(invoice)
      this.loading = false
      this.showCapture = true
    },

    async submitDownload ({ email, newsletterConsent }) {
      this.loadingCapture = true
      this.showCapture = true
      await DocumentService.sendAsMail(this.documentUuid, email, this.$router)
      this.loadingCapture = false
      this.trackInvoiceDownload()
      this.showCapture = false
      if (newsletterConsent) {
        await UserService.createConsent(this.documentUuid)
      }
    },
    trackInvoiceDownload () {
      window.dataLayer.push({ event: 'invoice_download_gtm' })
    },
    async sendAsEnvelope (invoice) {
      this.loading = true
      const documentUuid = await InvoiceService.createInvoice(invoice)
      this.loading = false

      const route = this.$router.resolve({ name: 'EnvelopeCreate', params: { documentUuid: documentUuid } })
      window.open(route.href)
    }
  }
}
</script>
