<template>
  <div class="tw-text-center">
    <p>
      Gebe deine E-Mail ein um zu bezahlen.
    </p>
    <div class="tw-ml-auto tw-mr-auto">
      <v-text-field
        label="E-Mail"
        dense
        v-model="email"
        :error="v$.email.$error"
      ></v-text-field>
    </div>
    <v-btn
      class="tw-text-white"
      elevation="2"
      color="rgba(255,77,0,0.9)"
      @click="submit"
    >
      Fortfahren
    </v-btn>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import UserService from '@/services/user.service'

export default {
  props: {
    documentUuid: String
  },
  data: function () {
    return {
      email: null
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      email: { email, required }
    }
  },
  computed: {
    emailErrorMessages () {
      if (this.v$.email.$silentErrors.length > 0) {
        return ['Die E-Mail hat ein ungültiges format.']
      }
      return []
    }
  },
  methods: {
    async submit () {
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        await UserService.assignUserToDocument(this.documentUuid, this.email)
        this.$emit('success', this.email)
      }
    }
  }
}
</script>
