<template>
  <div class="tw-flex tw-items-center">
    <div class="tw-inline-block fox-width-20">
      {{ itemIndex + 1 }}.
    </div>
    <div class="fox-invoice-item-row tw-flex tw-items-center tw-justify-between">
      <input v-model="invoiceItem.description"
             type="text"
             class="fox-input--simple fox-width-200 tw-ml-1"
             v-bind:class="classes.description">
      <div class="tw-inline-block tw-flex fox-width-150 tw-ml-1">
        <vue-autonumeric
          class="fox-input--simple fox-width-80"
          v-model="invoiceItem.quantity"
          :options="autonumericOptions.quantity"
          v-bind:class="classes.quantity"
        ></vue-autonumeric>
        <div class="fox-input--simple-unit-select fox-width-70 ml-1"
             v-bind:class="classes.unit">
          <select class="fox-width-40 tw-cursor-pointer"
                  v-model="invoiceItem.unit"
          >
            <option value disabled></option>
            <option value="piece">Stk</option>
            <option value="square_meter">m²</option>
            <option value="meter">m</option>
            <option value="kilogram">kg</option>
            <option value="tons">t</option>
            <option value="linear_meter">lfm</option>
            <option value="lump_sum">pauschal</option>
            <option value="cubic_meter">m³</option>
            <option value="hour">Std</option>
            <option value="kilometer">km</option>
            <option value="percentage">%</option>
            <option value="days">Tag(e)</option>
            <option value="liter">L</option>
          </select>
          <div class="fox-width-20">
            <div class="select-triangle"></div>
          </div>
        </div>
      </div>

      <vue-autonumeric
        class="fox-input--simple fox-width-120 ml-1"
        v-model="invoiceItem.unitPrice"
        :options="autonumericOptions.unitPrice"
        v-bind:class="classes.unitPrice"
      ></vue-autonumeric>
      <div class="tw-flex tw-align-center fox-width-100 tw-ml-1">
        <vue-autonumeric
          class="fox-input--simple fox-width-50"
          v-model="invoiceItem.vatPercentage"
          :options="autonumericOptions.vatPercentage"
          v-bind:class="classes.vatPercentage"
        ></vue-autonumeric>
        <span class="fox-input--symbol-panel fox-width-30 tw-flex tw-items-center tw-justify-center tw-ml-1">%</span>
      </div>
      <div class="fox-width-90 tw-ml-1">
        {{ totalAmount }}
      </div>
    </div>
    <v-icon
      v-if="itemIndex > 0"
      color="gray darken-2"
      @click="removeItem"
    >
      mdi-trash-can-outline
    </v-icon>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { VueAutonumeric } from 'vue-autonumeric/src'

export default {
  name: 'InvoiceItem',
  components: {
    VueAutonumeric
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    invoiceItem: {
      type: Object
    },
    itemIndex: {
      type: Number
    }
  },
  validations () {
    return {
      invoiceItem: {
        description: { required },
        quantity: { required },
        unit: { required },
        unitPrice: { required },
        vatPercentage: { required }
      }
    }
  },
  computed: {
    classes: function () {
      return {
        description: {
          'fox-input--error': this.v$.invoiceItem.description.$error
        },
        quantity: {
          'fox-input--error': this.v$.invoiceItem.quantity.$error
        },
        unit: {
          'fox-input--error': this.v$.invoiceItem.unit.$error
        },
        unitPrice: {
          'fox-input--error': this.v$.invoiceItem.unitPrice.$error
        },
        vatPercentage: {
          'fox-input--error': this.v$.invoiceItem.vatPercentage.$error
        }
      }
    },
    totalAmount () {
      let grossAmountFactor = null
      let total = null

      if (this.invoiceItem.vatPercentage) {
        grossAmountFactor = 1 + this.invoiceItem.vatPercentage / 100
      }

      if (this.invoiceItem.quantity && this.invoiceItem.unitPrice) {
        total = this.invoiceItem.quantity * this.invoiceItem.unitPrice
      }

      if (grossAmountFactor) {
        total = total * grossAmountFactor
      }

      if (!total) {
        return null
      }

      return total.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
    }
  },
  watch: {
    invoiceItem: {
      handler () {
        this.updateItem()
      },
      deep: true
    }
  },
  methods: {
    updateItem () {
      this.$emit('updateItem', this.invoiceItem, this.itemIndex)
    },
    removeItem () {
      this.$emit('removeItem', this.itemIndex)
    }
  },
  data: function () {
    return {
      autonumericOptions: {
        quantity: {
          minimumValue: '0',
          decimalCharacter: ',',
          digitGroupSeparator: '',
          currencySymbol: ''
        },
        unitPrice: {
          minimumValue: '0',
          decimalCharacter: ',',
          digitGroupSeparator: '.',
          currencySymbol: ' €',
          currencySymbolPlacement: 's'
        },
        vatPercentage: {
          minimumValue: '0',
          maximumValue: '100',
          currencySymbol: '',
          decimalPlaces: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
