<template>
  <div>
    <div class="tw-flex">
      <div class="fox-width-20"></div>
      <div class="fox-invoice-item-row tw-flex tw-items-center tw-font-bold">
        <div class="fox-width-200">Beschreibung</div>
        <div class="fox-width-150">Menge</div>
        <div class="fox-width-120">Einzelpreis</div>
        <div class="fox-width-50">USt.</div>
      </div>
      <div></div>
    </div>
    <InvoiceItem v-for="(item, key) in invoiceItems" :invoice-item="item" :key="key" :item-index="key"
                     @removeItem="removeInvoiceItem" @updateItem="updateInvoiceItem"/>
    <button @click="addInvoiceItem" class="tw-block tw-text-left tw-mt-2 fox-blue-text">+ Position hinzufügen</button>
  </div>
</template>

<script>
import InvoiceItem from '@/components/Invoice/Create/InvoiceItem'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'InvoiceItemContainer',
  components: {
    InvoiceItem: InvoiceItem
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    initialItems: {
      type: Array
    }
  },
  data: function () {
    return {
      invoiceItems: this.initialItems
    }
  },
  methods: {
    removeInvoiceItem (itemIndex) {
      this.invoiceItems.splice(itemIndex, 1)

      this.$emit('updateInvoiceItems', this.invoiceItems)
    },
    addInvoiceItem () {
      const position = this.invoiceItems.length + 1

      this.invoiceItems.push(
        {
          position: position,
          description: null,
          quantity: null,
          unit: null,
          unitPrice: null,
          vatPercentage: null
        }
      )

      this.$emit('updateInvoiceItems', this.invoiceItems)
    },
    updateInvoiceItem (item, itemIndex) {
      this.invoiceItems[itemIndex] = item
      this.$emit('updateInvoiceItems', this.invoiceItems)
    }
  }
}

</script>
