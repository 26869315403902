import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'

const UserService = {
  assignUserToDocument: async function (documentUuid, email) {
    try {
      return await ApiService.post('/user/assign-document', {
          document_uuid: documentUuid,
          email: email
        }
      ).then(res => {
        return res.data.uuid
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  createConsent: async function (documentUuid) {
    try {
      return await ApiService.post('/user/consent', {
          document_uuid: documentUuid
        }
      ).then(res => {
        return res.data.uuid
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  }
}

export default UserService
