<template>
  <v-row>
    <v-col>
      <img
        class="fox-logo-row"
        :src="require('@/assets/images/rechnungsfuchs_logo_langform_schwarz.png')"
        alt="Rechnungsfuchs Logo">
    </v-col>
  </v-row>
</template>
<script>
export default {}
</script>
