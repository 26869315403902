const EnvelopeDataFactory = {
  formDataToRequest: function (documentUuid, envelope) {
    let addressDetails

    if (envelope.receiverAddressAddition) {
      addressDetails = {
        address_line_1: envelope.receiverName,
        address_line_2: envelope.receiverAddressAddition,
        address_line_3: envelope.receiverStreet,
        address_line_4: `${envelope.receiverZipCode} ${envelope.receiverCity}`,
        address_line_5: envelope.receiverAddition
      }
    } else {
      addressDetails = {
        address_line_1: envelope.receiverName,
        address_line_2: envelope.receiverStreet,
        address_line_3: `${envelope.receiverZipCode} ${envelope.receiverCity}`,
        address_line_4: envelope.receiverAddition,
        address_line_5: null
      }
    }

    const baseData = {
      document_uuid: documentUuid,
      zip_code: envelope.receiverZipCode,
      city: envelope.receiverCity,
      country_code: envelope.receiverCountryCode,
      sender_address_line_1: envelope.senderName,
      sender_street: envelope.senderStreet,
      sender_zip_code: envelope.senderZipCode,
      sender_city: envelope.senderCity
    }

    return { ...addressDetails, ...baseData }
  },
  requestToRawEnvelope: function (envelope) {
    return {
      uuid: envelope.uuid,
      documentUuid: envelope.document_uuid,
      receiverZipCode: envelope.zip_code,
      receiverCity: envelope.city,
      receiverCountryCode: envelope.country_code,
      addressLine1: envelope.address_line_1,
      addressLine2: envelope.address_line_2,
      addressLine3: envelope.address_line_3,
      addressLine4: envelope.address_line_4,
      addressLine5: envelope.address_line_5,
      senderName: envelope.sender_address_line_1,
      senderStreet: envelope.sender_street,
      senderZipCode: envelope.sender_zip_code,
      senderCity: envelope.senderCity
    }
  }
}

export { EnvelopeDataFactory }
