<template>
  <v-app>
    <div class="fox-loading-spinner">
      <v-progress-circular v-show="loading"
        indeterminate
        :size="70"
        :width="7"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-container>
      <LogoRow/>
      <v-row>
        <v-col class="tw-text-center">
          <h1>Abo erfolgreich abgeschlossen</h1>
          <p class="tw-mt-4 tw-text-lg">
            Vielen Dank für dein Abo. Dein Zahlungsvorgang war erfolgreich!
          </p>
          <v-icon large color="green" class="tw-mt-4">mdi-check-circle</v-icon>
        </v-col>
      </v-row>
      <v-row class="tw-mt-10">
        <v-spacer/>
        <v-col cols="6">
          <div class="fox-success-message tw-text-center tw-pt-5 tw-pb-5 tw-rounded-3xl">
            <h2>Abo-Details</h2>
            <p class="tw-mt-5 tw-text-xl">Dein Abo wurde erfolgreich aktiviert. Du kannst nun alle Premium-Funktionen uneingeschränkt nutzen.</p>
            <v-btn class="tw-mt-5 tw-text-white" elevation="2" color="rgba(255,77,0,0.9)" @click="goToDashboard">
              Zurück zum Dashboard
            </v-btn>
          </div>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LogoRow from '@/components/Common/LogoRow.vue'
import ApiService from '@/services/api.service'

export default {
  components: {
    LogoRow
  },
  data () {
    return {
      loading: false,
      sessionId: null
    }
  },
  async mounted () {
    this.loading = true
    const urlParams = new URLSearchParams(window.location.search)
    this.sessionId = urlParams.get('sessionId')
    if (this.sessionId) {
      try {
        await ApiService.post('/subscription/payment/post', { sessionId: this.sessionId })
        this.loading = false
      } catch (error) {
        console.error('Error posting sessionId to API:', error)
        this.loading = false
      }
    } else {
      console.error('No sessionId found in query params')
      this.loading = false
    }
  },
  methods: {
    goToDashboard () {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style scoped>
.fox-success-message {
  background-color: #f1f8f5;
  border: 1px solid #ccc;
}
</style>
